import { action, flow, makeObservable, observable } from 'mobx';
import { nanoid } from 'nanoid';

import { hasValue } from 'src/packages/utils/has-value';
import { isStringNumberOrBoolean } from 'src/packages/utils/is-string-number-or-boolean';

import type { INotificationsService } from '../notifications-service';
import type { AxiosInstance } from 'axios';

import { DirectoriesStorageServiceApi } from './directories-storage-service-api';

export const REF_QUERY_REG_EXP = /([$%@]){([0-9A-Za-z.-_]+)}/g;

export type TStorageObject<T extends Record<string, unknown> = Record<string, unknown>> = {
  id: number;
  data: T;
  createdAt: number;
  objectType: string;
  status: 'ACTIVE' | 'DELETED';
  updatedAt: number;
};

export type TRefQuery = {
  objectType: string;
  joinedAlias?: string;
  join?: TJoin[];
  where?: Record<string, string | number | boolean | null | TRefQueryAdditionalCondition[]>[];
  order?: TJoinOrder[];
  hideInResult?: boolean;
};

export type TJoin = {
  joinType?: string;
  hideInResult?: boolean;
  mainObjectAlias: string;
  mainAttribute: string;
  joinedObjectType: string;
  joinedAttribute: string;
  joinedAlias: string;
  where?: Record<string, string | number | boolean | null>[];
};

export type TRefQueryAdditionalCondition = {
  attr: string;
  value: string;
};

export type TJoinOrder = {
  attr: string;
  joinedAlias: string;
};

export interface IDirectoriesStorage {
  directories: Record<string, TStorageObject[]>;
  subscribeOnObjectsChange(objectName: string, callBack: (objects: TStorageObject[]) => void): VoidFunction;
  loadObjects<TStorageObjectData extends Record<string, unknown>>(
    objectName: string,
    useCache: boolean,
    params?: TGetObjectsParams,
    signal?: AbortSignal,
  ): Promise<TStorageObject<TStorageObjectData>[]>;
  loadObjectsByRefQuery<TStorageObjectData extends Record<string, unknown>>(
    refQuery: TRefQuery,
    values: Record<string, unknown>,
    signal?: AbortSignal,
  ): Promise<Record<string, TStorageObject<TStorageObjectData>>[]>;
  createObject<T = unknown>(objectName: string, objectData: T, signal?: AbortSignal): Promise<void>;
  updateObject<T = unknown>(objectName: string, id: number, objectData: T, signal?: AbortSignal): Promise<void>;
  updateObjectList(
    objectName: string,
    objectList: Partial<TStorageObject<Record<string, unknown>>>[],
    signal?: AbortSignal,
  ): Promise<void>;
  changeObjectStatus(objectName: string, id: number, status: 'ACTIVE' | 'DELETED', signal?: AbortSignal): Promise<void>;
}

export type TGetObjectsParams = {
  withDeleted?: boolean;
  offset?: number;
  limit?: number;
  filterMap?: Record<string, unknown>;
};

type TSubscribeItem = { id: string; fn: (objects: TStorageObject[]) => void };

export class DirectoriesStorageService implements IDirectoriesStorage {
  private readonly api: DirectoriesStorageServiceApi;
  @observable private readonly subscribes: Record<string, TSubscribeItem[]> = {};
  private readonly currentObjectRequests = observable.map<string, Promise<TStorageObject[]>>();
  @observable.deep readonly directories: Record<string, TStorageObject[]> = {};

  constructor(agent: AxiosInstance, notificationsService: INotificationsService) {
    this.api = new DirectoriesStorageServiceApi(agent);
    makeObservable(this);
  }

  private filterInvalidJoinWhere(refQuery: TRefQuery): TRefQuery {
    const joinWhere = refQuery.where
      ? {
          where: refQuery.where.filter((where) => {
            const whereValue = where['value'];
            return hasValue(whereValue) && whereValue !== 'undefined' && whereValue !== 'null';
          }),
        }
      : {};

    const join = (() => {
      if (!refQuery.join) {
        return {};
      }

      return {
        join: refQuery.join.map((join) => {
          const where = join.where
            ? {
                where: join.where.filter((where) => {
                  const whereValue = where['value'];

                  return hasValue(whereValue) && whereValue !== 'undefined' && whereValue !== 'null';
                }),
              }
            : {};

          return {
            ...join,
            ...where,
          };
        }),
      };
    })();

    return {
      ...refQuery,
      ...joinWhere,
      ...join,
    };
  }

  @action.bound
  subscribeOnObjectsChange(objectName: string, callBack: (objects: TStorageObject[]) => void): VoidFunction {
    if (!this.subscribes[objectName]) {
      this.subscribes[objectName] = [];
    }

    const subObj = {
      id: nanoid(9),
      fn: callBack,
    };

    this.subscribes[objectName].push(subObj);

    return () => {
      if (!this.subscribes[objectName]) {
        console.warn('due to some reason subscribers for given objectType is not presented', objectName);
        return;
      }

      const index = this.subscribes[objectName].findIndex((item) => item.id === subObj.id);

      if (index >= 0) {
        this.subscribes[objectName].splice(index, 1);
      }
    };
  }

  @flow.bound
  async *loadObjects<TStorageObjectData extends Record<string, unknown>>(
    objectName: string,
    useCache: boolean = false,
    params: TGetObjectsParams = {},
    signal?: AbortSignal,
  ): Promise<TStorageObject<TStorageObjectData>[]> {
    if (useCache) {
      const existingDirectory = this.directories[objectName];
      if (existingDirectory) {
        return existingDirectory;
      }
    }

    const currentRequest =
      this.currentObjectRequests.get(objectName) || this.api.loadObjects(objectName, params, signal);

    try {
      const objects = await currentRequest;
      yield;

      this.directories[objectName] = objects ?? [];

      if (this.subscribes[objectName]?.length) {
        this.subscribes[objectName].forEach(({ fn }) => fn(objects ?? []));
      }

      return objects;
    } finally {
      this.currentObjectRequests.delete(objectName);
    }
  }

  @flow.bound
  async *loadObjectsByRefQuery<TStorageObjectData extends Record<string, unknown>>(
    refQuery: TRefQuery,
    values: Record<string, unknown>,
    signal?: AbortSignal,
  ): Promise<Record<string, TStorageObject<TStorageObjectData>>[]> {
    const stringifiedRefQuery = JSON.stringify(refQuery);

    const parseKey = (_: string, sign: string, key: string): string => {
      const valueKey = `${sign}${key}`;
      const value = values[valueKey];

      if (!hasValue(value) || (Array.isArray(value) && !value.length)) {
        return 'null';
      }

      if (isStringNumberOrBoolean(value)) {
        return value.toString();
      }

      if (Array.isArray(value)) {
        return value.join(',');
      }

      return JSON.stringify(value);
    };

    const stringifiedRefQueryWithSetValues = stringifiedRefQuery.replace(REF_QUERY_REG_EXP, parseKey);
    const refQueryWithFilteredInvalidWhere = this.filterInvalidJoinWhere(JSON.parse(stringifiedRefQueryWithSetValues));

    const data = await this.api.loadByRefQuery(refQueryWithFilteredInvalidWhere, signal);
    yield;

    return data;
  }

  @flow.bound
  async *createObject<T = unknown>(objectName: string, objectData: T, signal?: AbortSignal): Promise<void> {
    const { id } = await this.api.createObject(objectName, objectData, signal);
    yield;

    if (this.directories[objectName]) {
      const newObject = await this.api.loadObjectById(objectName, id);
      yield;
      this.directories[objectName].push(newObject);

      if (this.subscribes[objectName]?.length) {
        this.subscribes[objectName].forEach(({ fn }) => fn(this.directories[objectName]));
      }
    }
  }

  @flow.bound
  async *updateObject<T = unknown>(objectName: string, id: number, objectData: T, signal?: AbortSignal): Promise<void> {
    await this.api.updateObject(objectName, id, objectData, signal);
    yield;

    if (this.directories[objectName]) {
      const updatedObject = await this.api.loadObjectById(objectName, id);
      yield;
      const index = this.directories[objectName].findIndex((object) => object.id === updatedObject.id);

      this.directories[objectName].splice(index, 1, updatedObject);

      if (this.subscribes[objectName]?.length) {
        this.subscribes[objectName].forEach(({ fn }) => fn(this.directories[objectName]));
      }
    }
  }

  async updateObjectList(
    objectName: string,
    newlist: (Partial<TStorageObject> & { data: Record<string, unknown> })[],
    signal?: AbortSignal,
  ) {
    await this.api.updateObjectList(objectName, newlist, signal);

    const objects = await this.api.loadObjects(objectName);
    this.directories[objectName] = objects ?? [];
  }

  @flow.bound
  async *changeObjectStatus(objectName: string, id: number, status: 'ACTIVE' | 'DELETED', signal?: AbortSignal) {
    await this.api.updateStatus(objectName, id, status, signal);
    yield;

    if (this.directories[objectName]) {
      const objectList = this.directories[objectName];
      const object = objectList.find((object) => object.id === id);

      if (object) {
        const index = objectList.indexOf(object);
        const newObject = { ...object };

        newObject.status = status;

        objectList.splice(index, 1, newObject);
        this.directories[objectName] = objectList;

        if (this.subscribes[objectName]?.length) {
          this.subscribes[objectName].forEach(({ fn }) => fn(this.directories[objectName]));
        }
      }
    }
  }
}
