import { Add } from '@profgeosoft-ui/icons';
import { Button, Modal } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ImportImage from 'src/packages/assets/images/import.svg?react';
import { wrap } from 'src/packages/mobx-di/wrap';
import { checkIsNode } from 'src/packages/utils/checkIsNode';

import type { ChangeEvent, DragEvent as ReactDragEvent } from 'react';
import type { MainDirectoryService } from 'src/services/directory-service';

import styles from './import-modal.module.scss';

type Props = {
  mainDirectory: MainDirectoryService;
  open: boolean;
  onClose(): void;
};

const ALLOWED_FILE_TYPES: string[] = ['application/json'];

function isValidFileExtension(fileExtension?: string): boolean {
  if (!fileExtension) return false;
  return ALLOWED_FILE_TYPES.includes(fileExtension);
}

export const ImportModal = wrap<Props>(function ImportModal({ mainDirectory, open, onClose }) {
  const { t } = useTranslation();
  const [isDragOver, setIsDragOver] = useState(false);
  const [isWrongExtension, setIsWrongExtension] = useState(false);

  const { isImporting, importData } = mainDirectory;

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const dragAreaRef = useRef<HTMLInputElement | null>(null);

  const handleClick = () => {
    const inputNode = fileInputRef.current;

    if (!inputNode) {
      return;
    }

    inputNode.click();
  };

  const handleDrop = (event: ReactDragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (!checkIsNode(event.target) || !dragAreaRef.current?.contains(event.target)) {
      return;
    }

    const file = event.dataTransfer?.files[0];

    if (!isValidFileExtension(file?.type)) {
      setIsWrongExtension(false);
      setIsDragOver(false);
      return;
    }

    if (file) {
      importData(file, onClose);
    }

    setIsDragOver(false);
    setIsWrongExtension(false);
  };

  const handleChoseFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      importData(file, onClose);
    }
  };

  useEffect(() => {
    const handleDragOver = (event: DragEvent) => {
      event.preventDefault();

      if (checkIsNode(event.target) && dragAreaRef.current?.contains(event.target)) {
        setIsDragOver(true);

        if (!isValidFileExtension(event.dataTransfer?.items[0].type)) {
          setIsWrongExtension(true);
        } else {
          setIsWrongExtension(false);
        }

        return;
      }

      setIsWrongExtension(false);
      setIsDragOver(false);
    };

    document.body.addEventListener('dragover', handleDragOver);

    return () => {
      document.body.removeEventListener('dragover', handleDragOver);
    };
  }, []);

  return (
    <Modal
      title={t('modals:Import.title')}
      open={open}
      onClose={onClose}
      onClickOutside={null}
      headerClassName={styles.title}
    >
      <div className={styles.wrapper}>
        <p className={styles.descriptionText}>{t('modals:Import.description')}</p>
        <div ref={dragAreaRef} className={clsx(styles.dragArea, isWrongExtension && styles.dragArea_error)}>
          <div
            className={clsx(styles.dropZone, isDragOver && !isImporting && styles.dropZone_visible)}
            onDragLeave={() => {
              setIsDragOver(false);
              setIsWrongExtension(false);
            }}
            onDrop={handleDrop}
          />
          <div
            className={clsx(
              styles.importImageContainer,
              isDragOver && !isImporting && styles.importImageContainer_active,
            )}
          >
            <ImportImage className={styles.importImg} />
          </div>
          <input
            ref={fileInputRef}
            disabled={isImporting}
            className={styles.fileInput}
            type="file"
            id="importFile"
            aria-hidden
            accept=".json"
            onChange={handleChoseFile}
          />

          {!isImporting && isWrongExtension && isDragOver && (
            <p className={styles.wrongExtensionText}>{t('modals:Import.wrongExtension')}</p>
          )}
          {!isImporting && !isWrongExtension && isDragOver && (
            <p className={styles.uploadFileText}>{t('modals:Import.loadFile')}</p>
          )}

          {!isDragOver && (
            <Button
              loading={isImporting}
              disabled={isImporting}
              className={styles.importButton}
              icon={<Add />}
              onClick={handleClick}
            >
              {t('modals:Import.choseFile')}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
});
