import { requireService } from '@profgeosoft/di-ez';
import { Switch } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { wrap } from 'src/packages/mobx-di/wrap';

import styles from '../switchers/switchers.module.scss';

const ThemeSwitcher = wrap(function ThemeSwitcher() {
  const themeService = requireService('themeService');
  const { t } = useTranslation('common');

  const isLeftNodeActive = themeService.theme !== 'light';

  return (
    <Switch
      size="m"
      checked={themeService.theme === 'light'}
      onChange={themeService.toggle}
      containerClassName={styles.switchContainer}
      className={styles.switcher}
      knobClassName={clsx(styles.knob, !isLeftNodeActive && styles.activeLeft)}
      leftNode={<p className={clsx(styles.text, isLeftNodeActive && styles.activeText)}>{t('theme.dark')}</p>}
      rightNode={<p className={clsx(styles.text, !isLeftNodeActive && styles.activeText)}>{t('theme.light')}</p>}
    />
  );
});

export default ThemeSwitcher;
