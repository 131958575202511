import { throwApiError } from 'src/packages/errors';

import type { AxiosInstance } from 'axios';

export class DirectoriesServiceApi {
  private readonly agent: AxiosInstance;

  constructor(agent: AxiosInstance) {
    this.agent = agent;
  }

  async importData(formData: FormData): Promise<void> {
    try {
      await this.agent.post('/import/json', formData, {
        headers: { 'content-type': 'multipart/form-data' },
      });
    } catch (e) {
      throwApiError(e);
    }
  }
}
