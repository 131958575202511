import { ImportLight } from '@profgeosoft-ui/icons';
import { Tooltip, Button } from '@profgeosoft-ui/react';
import { t } from 'i18next';
import { useState } from 'react';

import { wrap } from 'src/packages/mobx-di/wrap';

import type { MainDirectoryService } from 'src/services/directory-service';

import { ImportModal } from './import-modal';

type Props = {
  directory: MainDirectoryService;
  className?: string;
};

export const ImportButton = wrap<Props>(function ImportButton({ className, directory }) {
  const [isModalOpened, setIsModalOpened] = useState(false);

  return (
    <>
      <Tooltip title={t('Импортировать данные')}>
        <Button variant="flat" className={className} onClick={() => setIsModalOpened(true)}>
          <ImportLight />
        </Button>
      </Tooltip>
      <ImportModal mainDirectory={directory} open={isModalOpened} onClose={() => setIsModalOpened(false)} />
    </>
  );
});
