import { requireService } from '@profgeosoft/di-ez';
import { TableCheckbox as TableCheckboxFromUI, type GridRenderCellParams } from '@profgeosoft-ui/react';

import { wrap } from 'src/packages/mobx-di/wrap';
import { assert } from 'src/packages/utils/assert';
import { CheckboxField } from 'src/services/directory-service/entities/controls/checkbox-field';

import type { DirectoryRecord } from 'src/services/directory-service/entities/directory-record.entity';
import type { TControlView } from 'src/services/directory-service/types';

import { Cell } from './cell/cell';

type Props = {
  params: GridRenderCellParams<DirectoryRecord>;
  columnView: TControlView;
};

export const TableCheckbox = wrap<Props>(function TestCheckbox({ params, columnView }) {
  const directoryPage = requireService('directoryPage');
  const directory = directoryPage.currentDirectory;

  const control = params.row.controlsMap.get(columnView.fieldId);
  assert(control instanceof CheckboxField, 'control is not checkbox');

  return (
    <Cell message={control?.error}>
      <TableCheckboxFromUI
        cellParams={params}
        checked={control.value}
        disabled={!directoryPage.isEditing}
        onChange={(value) => {
          directory?.directoryViewMediator?.onControlValueChange(params.row.recordId, control.fieldId, value);
        }}
      />
    </Cell>
  );
});
