import appbar from './appbar.json';
import common from './common.json';
import directoriesList from './directories-list.json';
import directory from './directory.json';
import modals from './modals.json';
import settings from './settings.json';
import sidebar from './sidebar.json';

export default {
  common,
  settings,
  directoriesList,
  directory,
  sidebar,
  appbar,
  modals,
};
